import React from "react";
import styled from "styled-components";



export const Page: React.FC<{children?: JSX.Element|JSX.Element[], className?: string}> = (props) => {
    const { children, className } = props;
    return (
        <StyledPage className={className}>
            { children }
        </StyledPage>
    )
};

export const StyledPage = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

`;