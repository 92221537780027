import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Link from "next/link";
import { useRouter } from "next/router";
import { DateTime } from "luxon";

import { Icon } from "./Icon";
import { Loader } from "./Loader";

import { EventType } from "types/event";
import { useTranslation } from "react-i18next";

import { Api } from "lib/api";

interface HomeEditionsListProps {
  events: EventType[] | null;
  sorting: SortingOptionType;
}

type SortingOptionType =
  | "event_date"
  | "alphabetically"
  | "creation_date"
  | "status";

export const HomeEditionsList: React.FC<HomeEditionsListProps> = (props) => {
  const { events, sorting } = props;

  const { t } = useTranslation("events");

  const [sortedEvents, setSortedEvents] = useState<EventType[] | null>(null);
  const [openEventId, setOpenEventId] = useState<string | null>(null);
  const [editions, setEditions] = useState<any[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const router = useRouter();

  const isEventOpen = (eventId: string): boolean => {
    return eventId === openEventId && !loading;
  };

  const eventClickHandler = (
    e: React.MouseEvent<HTMLElement>,
    eventId: string
  ): void => {
    e.preventDefault();
    if (eventId === openEventId) {
      setOpenEventId(null);
      setEditions(null);
    } else {
      setOpenEventId(eventId);
      fetchEditions(eventId);
    }
  };

  useEffect(() => {
    setSortedEvents(sortList(events, sorting));
  }, [events, sorting]);

  useEffect(() => {
    setEditions(sortList(editions, sorting));
  }, [sorting]);

  const sortList = (
    events: EventType[] | null,
    sortingMethod: SortingOptionType
  ) => {
    if (!events) return null;

    const eventsArr = [...events];

    switch (sortingMethod) {
      case "alphabetically":
        return eventsArr.sort((a, b) =>
          a.name.trim() < b.name.trim() ? -1 : 1
        );

      case "creation_date":
        return eventsArr.sort((a, b) => (a.created_at > b.created_at ? -1 : 1));

      case "event_date":
        //const now = +new Date().setHours(0,0,0,0);
        const today = DateTime.now().startOf("day");

        const noDates = eventsArr
          .filter((event) => !event.dates.start)
          .sort((a, b) => (a.created_at > b.created_at ? -1 : 1));
        const futureDates = eventsArr
          .filter(
            (event) =>
              !!event.dates.start &&
              // now < +new Date(event.date.start)
              today < DateTime.fromSQL(event.dates.start)
          )
          .sort((a, b) => (a.dates.start < b.dates.start ? -1 : 1));
        const pastDates = eventsArr
          .filter(
            (event) =>
              !!event.dates.start &&
              // now > +new Date(event.date.start)
              today > DateTime.fromSQL(event.dates.start)
          )
          .sort((a, b) => (a.dates.start > b.dates.start ? -1 : 1));

        return [...futureDates, ...pastDates, ...noDates];

      case "status":
        const openedStores = eventsArr
          .filter((event) => event.status.published)
          .sort((a, b) => {
            const dateA = a.dates.start ? a.dates.start : "";
            const dateB = b.dates.start ? b.dates.start : "";
            return dateA < dateB ? -1 : 1;
          });
        const closedStores = eventsArr
          .filter((event) => !event.status.published)
          .sort((a, b) => {
            const dateA = a.dates.start ? a.dates.start : "";
            const dateB = b.dates.start ? b.dates.start : "";
            return dateA < dateB ? -1 : 1;
          });

        return [...openedStores, ...closedStores];
      default:
        return eventsArr;
    }
  };

  const fetchEditions = async (eventId: string) => {
    try {
      setLoading(true);
      const response = await Api.get(`/events/${eventId}/editions`);
      const { editions, total } = response;
      if (total === 1) {
        router.push(`/events/${eventId}/editions/${editions[0].id}`);
        return;
      } else {
        setEditions(sortList(response.editions, sorting));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (!sortedEvents) return null;

  return (
    <StyledHomeEditionsList>
      <ul className="event-list">
        {sortedEvents.map((event) => {
          const editionsLength = event.editions.length;

          return (
            <li
              key={event.id}
              className={`event-list-item ${
                isEventOpen(event.id) ? "open" : ""
              }`}
            >
              <Link href="/" passHref legacyBehavior>
                <a
                  onClick={(e) => eventClickHandler(e, event.id)}
                  className="event"
                >
                  <div className="event-info">
                    <h5>
                      {event.name}
                      {event.status.published && (
                        <span className="indicator-store-open" />
                      )}
                    </h5>
                    {editionsLength > 1 && (
                      <p>
                        {t("event_list.editions", { number: editionsLength })}
                      </p>
                    )}
                  </div>
                  {editionsLength > 1 && (
                    <div className="event-show-editions-toggle">
                      {/* <Loader size="small" /> */}
                      {loading && openEventId === event.id ? (
                        <Loader size="small" />
                      ) : (
                        <Icon name="icon-arrow-down" />
                      )}
                    </div>
                  )}
                </a>
              </Link>
              {editionsLength > 1 && (
                <div className="editions">
                  <ul className="editions-list">
                    {editions &&
                      editions.map((edition) => {
                        return (
                          <li
                            key={event.id + "-" + edition.id}
                            className="editions-list-item"
                          >
                            <Link
                              href={`/events/${event.id}/editions/${edition.id}`}
                            >
                              {edition.name
                                ? edition.name
                                : t("event_list.first_edition")}
                              {edition.status === "open" && (
                                <span className="indicator-store-open" />
                              )}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              )}
              {loading && openEventId === event.id && (
                <div className="event-list-item-loading-bar">
                  <div className="event-list-item-loading-bar-fill" />
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </StyledHomeEditionsList>
  );
};

const AnimateFill = () => keyframes`
    0% { width: 0; }
    100% { width: 95%; }
`;

const StyledHomeEditionsList = styled.div`
  margin-bottom: 2rem;
  padding: 0 1.25rem;

  ul.event-list {
    list-style: none;
    padding: 0;

    li.event-list-item {
      border-radius: 0.75rem;
      background-color: ${(props) => props.theme.colors.dark400};
      margin-bottom: 0.5rem;
      overflow: hidden;
      position: relative;

      &:last-of-type {
        margin-bottom: 0;
      }

      .event {
        text-decoration: none;

        display: flex;
        align-items: center;
        padding: 1rem;

        .event-info {
          padding-right: 1rem;
          width: calc(100% - 2rem);

          h5 {
            font-size: 1.125rem;
            font-weight: 700;
            line-height: 1.2;
            color: #fff;
          }

          p {
            //margin-top: 0.25rem;
            font-size: 0.875rem;
            font-weight: 400;
            color: ${(props) => props.theme.colors.dark100};
          }
        }

        .event-show-editions-toggle {
          width: 1.5rem;
          height: 1.5rem;
          font-size: 1.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: auto;
          color: ${(props) => props.theme.colors.primary500};
          transition: all 200ms ease;
        }
      }

      .editions {
        /* display: none; */
        max-height: 0;
        transition: all 400ms ease;

        .editions-list {
          list-style: none;
          padding: 0;

          .editions-list-item {
            background-color: ${(props) => props.theme.colors.dark300};
            margin-bottom: 1px;
            font-family: 1rem;
            font-weight: 700;

            &:last-of-type {
              margin-bottom: 0;
            }

            a {
              text-decoration: none;
              color: inherit;
              padding: 0.75rem 1rem;
              display: block;
            }
          }
        }
      }

      &.open {
        .event-show-editions-toggle {
          transform: rotate(180deg);
        }

        .editions {
          /* display: block; */
          max-height: 100vh;
        }
      }

      .event-list-item-loading-bar {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 0.125rem;
        width: 100%;
        background-color: ${(props) => props.theme.colors.dark300};

        .event-list-item-loading-bar-fill {
          position: absolute;
          top: 0;
          left: 0;
          width: 96%;
          height: 100%;
          background-color: ${(props) => props.theme.colors.primary500};
          background-image: ${(props) => props.theme.gradient};

          animation-name: ${(props) => AnimateFill()};
          animation-duration: 6000ms;
          animation-iteration-count: 1;
          animation-timing-function: cubic-bezier(0, 0.94, 0.08, 0.98);
        }
      }
    }
  }

  .indicator-store-open {
    display: inline-block;
    margin-left: 0.25rem;
    margin-bottom: 1px;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.25rem;
    background-color: ${(props) => props.theme.colors.success};
  }
`;
