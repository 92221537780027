import React from "react";
import styled, { keyframes } from "styled-components";

interface LoadingBarProps {
  loading: boolean;
}

export const LoadingBar: React.FC<LoadingBarProps> = (props) => {
  const { loading } = props;
  return (
    <StyledLoadingBar>
      {loading && <div className="loading-bar-fill" />}
    </StyledLoadingBar>
  );
};

const AnimateFill = () => keyframes`
    0% { width: 0;}
    100% { width: 98%; }
`;

const StyledLoadingBar = styled.div`
  position: relative;
  height: 2px;
  width: calc(100% - 2.5rem);
  margin: 0 auto 0.5rem;
  //opacity: 0.6;
  /* background-color: ${(props) => props.theme.colors.dark300}; */

  .loading-bar-fill {
    position: absolute;
    border-radius: 2px;
    top: 0;
    left: 0;
    width: 98%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.primary500};
    background-image: ${(props) => props.theme.gradient};

    animation-name: ${(props) => AnimateFill()};
    animation-duration: 6000ms;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0, 0.94, 0.08, 0.98);
  }
`;
