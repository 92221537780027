import { useGesture } from "@use-gesture/react";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "redux/store";
import { Icon } from "./Icon";

export const ScrollArea: React.FC<{
  children?: JSX.Element | JSX.Element[];
  id?: string;
  refresh?: () => void;
}> = (props) => {
  const { children, id, refresh } = props;

  const { isTouchDevice } = useAppSelector((state) => {
    return {
      isTouchDevice: state.organizer.isTouchDevice,
    };
  });

  const { t } = useTranslation('common');

  const scrollAreaRef = useRef<HTMLDivElement>(null);

  // threshold in pixels needed to reach before release to refresh is posoble
  const pullToRefreshThreshold = 120;

  const [dragging, setDragging] = useState<boolean>(false);
  const [ startY, setStartY ] = useState<number>(0);
  const [ currentY, setCurrentY ] = useState<number>(0);
  const [ refreshMargin, setRefreshMargin] = useState<number>(-32);
  const [ refreshScale, setRefreshScale] = useState<number>(0);
  const [ pullToRefreshThresholdReached, setPullToRefreshThresholdReached ] = useState<boolean>(false); 

  const bindGestures = useGesture({
    onTouchStart: (state) => {
      if (refresh && isTouchDevice) onStart(state.event)
    },  
    onTouchMove: (state) => {
      if (refresh && isTouchDevice) onMove(state.event)
    },
    onTouchEnd: (state) => {
      if (refresh && isTouchDevice) onEnd(state.event);
    },
    onMouseDown: (state) => {
      if (refresh && !isTouchDevice) onStart(state.event)
    },
    onMouseMove: (state) => {
      if (refresh && !isTouchDevice) onMove(state.event);
    },
    onMouseUp: (state) => {
      if (refresh && !isTouchDevice) onEnd(state.event);
    },
    onMouseLeave: (state) => {
      setDragging(false);
      setRefreshMargin(-32);
      setRefreshScale(0);
      setPullToRefreshThresholdReached(false);
    }
  });

  const onStart = ( e: any) => {
  
    const area = scrollAreaRef.current;
    if ( !!area?.scrollTop && area.scrollTop > 0 ) {
      // If scrollArea is not at top, don't show pulldown action
      return;
    }

    setDragging(true);

    let y = 0
    if (e.type === 'touchstart') {
      y = e.touches[0].pageY;
    }
    if (e.type === 'mousedown') {
      y = e.pageY;
    }

    setStartY(y);
    setCurrentY(y);
  };

  const onMove = (e: any) => {
    if (!dragging) {
      return;
    }
   
    let y;
    if (e.type === 'touchmove') {
       y = e.touches[0].pageY;
     
    }
    if (e.type === 'mousemove') {
      y = e.pageY;
    }

    const difference = Math.floor(y - startY);
    setCurrentY(y);
    setRefreshMargin(Math.min(Math.max(difference - 32, -32), 0));
    setRefreshScale(Math.min(Math.max((difference - 16) / 32, 0), 1));

    if ( difference > pullToRefreshThreshold )  { setPullToRefreshThresholdReached(true) }
    if ( difference <= pullToRefreshThreshold )  { setPullToRefreshThresholdReached(false) }

  };

  const onEnd = (e: any) => {
    setDragging(false);
    
    if (pullToRefreshThresholdReached && refresh) {
      refresh();
    } 

    setRefreshMargin(-32);
    setRefreshScale(0);
    setPullToRefreshThresholdReached(false);
  }

  return (
    <StyledScrollArea id={id} ref={scrollAreaRef} {...bindGestures()} refreshMargin={refreshMargin} refreshScale={refreshScale}>
      <div className="refresh">
        
          {pullToRefreshThresholdReached 
            ? <span><span className="icon-wrapper"><Icon name="icon-refresh-line"/></span>{t('refresh.release')}</span>
            : <span><span className="icon-wrapper"><Icon name="icon-arrow-down-line"/></span>{t('refresh.pull')}</span> }
        
      </div>
      {children}
    </StyledScrollArea>
  );
};

const StyledScrollArea = styled.div<{refreshMargin: number, refreshScale: number}>`
  flex: 1 1 0;
  overflow-y: scroll;
  overflow-x: hidden;
  overscroll-behavior-y: auto;
  overscroll-behavior-x: none;

  position: relative;

 

  .refresh {
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 0.75rem;

    margin-top: ${props => props.refreshMargin + 'px'};
    
    span {
      transform: scale(${props => props.refreshScale});
      color: ${props => props.theme.colors.primary500};
      display: inline-flex;
      align-items: center;

      .icon-wrapper {
        width: 0.875rem;
        height: 0.875rem;
        font-size: 0.875rem;
        margin-right: 0.25rem;
      }
    }
  }
`;
