import { useEffect, useState } from "react";
import styled from "styled-components";
import Link from "next/link";

import { useAuth } from "@eventsquare/uikit";

import { useAppDispatch, useAppSelector } from "redux/store";
import { setActiveTab, setEventsList } from "redux/slices/organizerSlice";

import { HomeEditionsList } from "components/HomeEditionsList";
import { Icon } from "components/Icon";
import { SelectSmall } from "components/formelements/SelectSmall";
import { Loader } from "components/Loader";

import { EventType } from "types/event";

import {
  formatCurrency,
  getLocalStorage,
  setLocalStorage,
} from "lib/functions";
import { Page } from "components/Page";
import { ScrollArea } from "components/ScrollArea";
import { useTranslation } from "react-i18next";
import { LoadingBar } from "components/LoadingBar";
import Head from "next/head";
import { Api } from "lib/api";

type SortingOptionType =
  | "event_date"
  | "alphabetically"
  | "creation_date"
  | "status";

const EditionsList = () => {
  const { account, eventsList } = useAppSelector((state) => {
    return {
      account: state.organizer.account,
      eventsList: state.organizer.eventsList,
    };
  });
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation("events");

  const localStorageEventSorting = getLocalStorage("eventSorting");

  const [eventsLoading, setEventsLoading] = useState<boolean>(false);
  const [selectedSortingOption, setSelectedSortingOption] =
    useState<SortingOptionType>(
      localStorageEventSorting
        ? (localStorageEventSorting as SortingOptionType)
        : "event_date"
    );

  useEffect(() => {
    dispatch(setActiveTab(null));
    if (!account) return;
    if (!eventsList) {
      fetchEvents(account.id);
    }
  }, [account]);

  useEffect(() => {
    setLocalStorage("eventSorting", selectedSortingOption);
  }, [selectedSortingOption]);

  const fetchEvents = async (accountId: string) => {
    setEventsLoading(true);
    try {
      const response = await Api.get(`/accounts/${accountId}/events`);

      const events = response.events.filter(
        (event: EventType) => event.editions.length > 0
      );
      dispatch(setEventsList(events));
    } catch (error) {
      console.error(error);
    } finally {
      setEventsLoading(false);
    }
  };

  const changeSorting = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setSelectedSortingOption(e.target.value as SortingOptionType);
  };

  if (!user || !account) return null;

  return (
    <>
      <Head>
        <title>{`${account.name} | ${t("meta.title")}`}</title>
        <meta name="description" content={`${t("meta.description")}`}></meta>
      </Head>

      <StyledHomePage>
        <div>
          <div className="home-header">
            <img
              className="esq-logo"
              src="/images/eventsquare-logo.svg"
              alt="EventSquare"
            />
            <Link href="/settings" className="settings-link">
              <div className="icon-wrapper">
                <Icon name="icon-settings-cog" />
              </div>
            </Link>
          </div>
          <div className="home-account">
            <div className="home-account-data">
              <h3>{account.name}</h3>
              <p className="saldo">
                {formatCurrency(account.balance, "EUR", i18n.language)}
              </p>
            </div>
          </div>
          <div className="home-topbar">
            <h1>{t("title")}</h1>
            <SelectSmall value={selectedSortingOption} onChange={changeSorting}>
              <option value="event_date">{t("sorting.event_date")}</option>
              <option value="alphabetically">
                {t("sorting.alphabetically")}
              </option>
              <option value="creation_date">
                {t("sorting.creation_date")}
              </option>
              <option value="status">{t("sorting.status")}</option>
            </SelectSmall>
          </div>
          <LoadingBar loading={eventsLoading} />
        </div>
        <ScrollArea
          refresh={() => {
            fetchEvents(account.id);
          }}
        >
          <>
            {eventsLoading && !eventsList && <Loader />}
            {eventsList && !!eventsList.length && (
              <HomeEditionsList
                events={eventsList}
                sorting={selectedSortingOption}
              />
            )}
          </>
        </ScrollArea>
      </StyledHomePage>
    </>
  );
};

const StyledHomePage = styled(Page)`
  .home-header {
    padding: 1.5rem 4rem;
    position: relative;
    margin-bottom: 0.25rem;

    img.esq-logo {
      width: 10rem;
      margin: 0 auto;
    }

    a.settings-link {
      position: absolute;
      top: 50%;
      right: 0.75rem;
      transform: translateY(-50%);
      text-decoration: none;

      div.icon-wrapper {
        font-size: 1.5rem;
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${(props) => props.theme.colors.primary500};
      }
    }
  }
  .home-account {
    padding: 0 1.25rem;

    .home-account-data {
      background-color: ${(props) => props.theme.colors.primary500};
      background-image: ${(props) => props.theme.gradient};
      color: #fff;
      border-radius: 0.75rem;
      text-align: center;
      padding: 1.75rem;
      margin-bottom: 1.5rem;

      h3 {
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 1;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-bottom: 0.75rem;
      }

      p.saldo {
        font-weight: 700;
        font-size: 2.25rem;
        margin-bottom: 0;
        line-height: 1;
      }
    }
  }

  .home-topbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
    padding: 0 1.25rem;

    h1 {
      margin-right: 1rem;
    }
  }
`;

export default EditionsList;
