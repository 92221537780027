import React from 'react';
import styled from 'styled-components';

import { Icon } from "components/Icon";

interface SelectSmallProps {
    value: string;
    onChange:  (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const SelectSmall: React.FC<SelectSmallProps> = (props) => {
    const { value, onChange, children } = props;   

    return (
    <StyledSelectSmall>

    <select value={value} onChange={onChange}>
        {children}
    </select>
    <div className="icon-wrapper">
      <Icon name="icon-arrow-down" />
      </div>
    </StyledSelectSmall>);
}

const StyledSelectSmall = styled.div`
background-color: ${(props) => props.theme.colors.dark300};
  border-radius: 0.5rem;
  color: ${(props) => props.theme.colors.white};
  display: inline-block;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  position: relative;

  
    select {
  // A reset of styles, including removing the default dropdown arrow
  appearance: none;
  // Additional resets for further consistency
  background-color: transparent;
  border: none;
  /* padding: 0 1em 0 0; */
  padding: 0.25rem 1.5rem 0.25rem 1rem;

  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  color: inherit;
  outline: none;

  }

  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    width: 1rem;
    height: 1rem;
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    color: ${props => props.theme.colors.primary500};
    pointer-events: none;
  }

`;